import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#02102A", light: "#3580F5", dark: "#122548" },
    secondary: {
      main: "#35953D",
    },
    text: {
      secondary: "white",
      tertiary: "#5E7096",
    },
    border: {
      textfield: "#D1D7E4",
    },
  },
  typography: {
    fontFamily: "Lato",
  },
  components: {},
});

export default theme;
