export const DEFAULT_ERROR = "Something went wrong. Please try again later.";

export const DrawerWidth = 100;

export const ROWS_PER_PAGE_INFLUENCER_OPTIONS = [100, 200, 500];

export const NEWS_APPROVAL_STATUSES = ["CREATED", "REJECTED", "REVIEWED"];
export const SAMPLE_IMG_URL =
  "https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.png";
export const USER_ROLES = ["ADMIN", "CREATOR", "USER"];
